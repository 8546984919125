import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { backendURL } from "../backendURL";

const ScholarshipAllotmentCorner = () => {
  const { examId } = useParams();
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [examNames, setExamNames] = useState([]);

  useEffect(() => {
    fetchExamNames(); // Fetch exam names when component mounts
    if (examId) {
      fetchStudentData(examId); // Fetch data initially based on examId
    }
  }, [examId]); // Added examId as dependency to fetch data when it changes

  const fetchExamNames = async () => {
    try {
      const response = await fetch(`${backendURL}/exam/getAll`);
      if (response.ok) {
        const data = await response.json();
        setExamNames(data.data.map((exam) => ({ id: exam._id, name: exam.examName })));
      } else {
        console.error("Failed to fetch exam names:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching exam names:", error);
    }
  };

  const fetchStudentData = async (examId) => {
    try {
      const response = await fetch(
        `${backendURL}/result/getAllResultsWithUserDetails/${examId}`
      );
      if (response.ok) {
        const data = await response.json();
        console.log("Data from backend:", data); // Log the data received from backend
        const enrichedResults = data.data.map((result) => ({
          _id: result._id,
          percentage: result.percentage,
          studentId: result.studentId,
          examId: result.examId,
          timeTaken: result.timeTaken,
          age: result.age,
          createdAt: result.createdAt,
          updatedAt: result.updatedAt,
          classId: result.classId, // Include classId directly from result
        }));
        console.log("Enriched results:", enrichedResults); // Log the enriched results
        setStudents(enrichedResults);
      } else {
        console.error("Failed to fetch student data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching student data:", error);
    }
  };


  const handleExamChange = (event) => {
    const selectedExamId = event.target.value;
    navigate(`/allotscholarships/${selectedExamId}`);
  };

  const sendEmail = (studentId) => {
    // Replace with your email sending logic
    console.log(`Email sent to student with ID: ${studentId}`);
  };

  console.log("Students state:", students); // Debugging - Check the state of students

  return (
    <Paper elevation={3} style={{ padding: "20px" }}>
      <Typography variant="h5" gutterBottom>
        Allot Scholarships to Students
      </Typography>
      <FormControl>
        {/* <InputLabel id="exam-select-label">Select an Exam</InputLabel> */}
        <span>Select an Exam:</span>
        <Select
          labelId="exam-select-label"
          value={examId} // Make sure examId is initially set to "Select an Exam"
          onChange={handleExamChange}
          inputProps={{
            name: "exam",
            id: "exam-select",
          }}
        >
          <MenuItem value="Select an Exam" disabled>Select an Exam</MenuItem>
          {examNames.map((exam) => (
            <MenuItem key={exam.id} value={exam.id}>
              {exam.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Profile Picture</TableCell>
              <TableCell>Student Name</TableCell>
              <TableCell>Exam Name</TableCell>
              <TableCell>Time Taken (mins)</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Class</TableCell>
              <TableCell>Percentage</TableCell>
              {/* <TableCell>Action</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {students.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8}>No student data available</TableCell>
              </TableRow>
            ) : (
              students.map((student, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Avatar src={`data:image/jpeg;base64,${student.studentId.profilePicture}`} />
                  </TableCell>
                  <TableCell>{`${student.studentId.firstName} ${student.studentId.lastName}`}</TableCell>
                  <TableCell>{student.examId.examName}</TableCell>
                  <TableCell>{student.timeTaken}</TableCell>
                  <TableCell>{student.age}</TableCell>
                  <TableCell>{student.classId ? student.classId.className : "N/A"}</TableCell>
                  <TableCell>{student.percentage}</TableCell>
                  {/* <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => sendEmail(student.studentId._id)}
                    >
                      Send Email
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ScholarshipAllotmentCorner;
