import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { backendURL } from "../backendURL";

const QuestionsTable = () => {
  const { examId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchQuestions();
  }, [examId]);

  const fetchQuestions = async () => {
    try {
      const response = await fetch(`${backendURL}/question/exam/${examId}`);
      const data = await response.json();
      if (response.ok && data.status) {
        setQuestions(data.data);
      } else {
        console.error("Failed to fetch questions:", data.message);
      }
    } catch (error) {
      console.error("Failed to fetch questions:", error);
    }
  };

  const handleUpdate = (question) => {
    setSelectedQuestion(question);
    setIsModalVisible(true);
    form.setFieldsValue({
      questionText: question.questionText,
      options: question.options.join(", "),
      correctAnswer: question.correctAnswer,
    });
  };

  const handleOk = async (values) => {
    try {
      const response = await fetch(`${backendURL}/question/${selectedQuestion._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          questionText: values.questionText,
          options: values.options.split(",").map(opt => opt.trim()),
          correctAnswer: values.correctAnswer,
        }),
      });

      if (response.ok) {
        fetchQuestions();
        setIsModalVisible(false);
        form.resetFields();
        navigate(`/questions/${examId}`); // Return to question list
      } else {
        console.error("Failed to update question");
      }
    } catch (error) {
      console.error("Error updating question:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleDelete = async (questionId) => {
    try {
      const response = await fetch(`${backendURL}/question/${questionId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        fetchQuestions();
        message.success('Question deleted successfully');
      } else {
        console.error("Failed to delete question");
        message.error('Failed to delete question');
      }
    } catch (error) {
      console.error("Error deleting question:", error);
      message.error('Error deleting question');
    }
  };

  const columns = [
    {
      title: "Question Text",
      dataIndex: "questionText",
      key: "questionText",
    },
    {
      title: "Options",
      dataIndex: "options",
      key: "options",
      render: options => options.join(", "),
    },
    {
      title: "Correct Answer",
      dataIndex: "correctAnswer",
      key: "correctAnswer",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Button 
          onClick={() => handleUpdate(record)} 
          type="primary" 
         
          style={{ marginRight: 8 }}
        >
          <EditOutlined />
        </Button>
          <Popconfirm
            title="Are you sure you want to delete this question?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
              <Button 
            type="primary" 
            danger 
          >
            <DeleteOutlined />
          </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div className="tabled">
      <h2>Exam ID: {examId}</h2>
      <Table
        columns={columns}
        dataSource={questions}
        pagination={false}
        className="ant-border-space"
      />

      <Modal
        title="Update Question"
        visible={isModalVisible}
        onOk={() => {
          document.getElementById("update-form").dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          id="update-form"
          initialValues={{
            questionText: selectedQuestion?.questionText,
            options: selectedQuestion?.options.join(", "),
            correctAnswer: selectedQuestion?.correctAnswer,
          }}
          onFinish={handleOk}
        >
          <Form.Item name="questionText" label="Question Text">
            <Input />
          </Form.Item>
          <Form.Item name="options" label="Options">
            <Input placeholder="Comma separated" />
          </Form.Item>
          <Form.Item name="correctAnswer" label="Correct Answer">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default QuestionsTable;
