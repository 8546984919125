import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { backendURL } from '../backendURL';

const ExamDetails = () => {
  const [exam, setExam] = useState(null);
  const [results, setResults] = useState([]);
  const { examId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendURL}/exam/getExamId/${examId}`);
        const responseData = await response.json();
        if (response.ok) {
          setExam(responseData.exam);
          setResults(responseData.results);
        } else {
          console.error('Error fetching exam:', responseData.error);
        }
      } catch (error) {
        console.error('Error fetching exam:', error);
      }
    };

    fetchData();
  }, [examId]);

  if (!exam) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h3>Exam Details: {exam.name}</h3>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            {/* <th style={{ padding: '8px', textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>Student ID</th> */}
            <th style={{ padding: '8px', textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>Student Name</th>
            <th style={{ padding: '8px', textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>Class Name</th>
            <th style={{ padding: '8px', textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>Created At</th>
            <th style={{ padding: '8px', textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>Is Exam Completed</th>
          </tr>
        </thead>
        <tbody>
          {results.map(student => (
            <tr key={student.studentId}>
              {/* <td style={{ padding: '8px', textAlign: 'center', border: '1px solid #ddd' }}>{student.studentId}</td> */}
              <td style={{ padding: '8px', textAlign: 'center', border: '1px solid #ddd' }}>{student.fullName}</td>
              <td style={{ padding: '8px', textAlign: 'center', border: '1px solid #ddd' }}>{student.className}</td>
              <td style={{ padding: '8px', textAlign: 'center', border: '1px solid #ddd' }}>{new Date(student.createdAt).toLocaleString()}</td>
              <td style={{ padding: '8px', textAlign: 'center', border: '1px solid #ddd' }}>{student.isExamCompleted ? 'Yes' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExamDetails;
