import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Typography } from 'antd';
import { backendURL } from '../backendURL';

const { Title } = Typography;

function LineChart() {
  const [lineChart, setLineChart] = useState({
    options: {
      chart: {
        id: 'exam-registrations',
      },
      xaxis: {
        categories: [],
        title: {
          text: 'Exam Names',
        },
      },
      yaxis: {
        title: {
          text: 'Number of Registrations',
        },
      },
    },
    series: [
      {
        name: 'Registrations',
        data: [],
      },
    ],
  });

  useEffect(() => {
    examRegisterCount();
  }, []);

  const examRegisterCount = async () => {
    try {
      const response = await fetch(`${backendURL}/examRegister-count`);
      if (response.ok) {
        const data = await response.json();
        const categories = data.map(exam => exam.examName);
        const seriesData = data.map(exam => exam.totalRegistrations);

        setLineChart(prevChart => ({
          options: {
            ...prevChart.options,
            xaxis: {
              categories,
            },
          },
          series: [
            {
              name: 'Registrations',
              data: seriesData,
            },
          ],
        }));
      } else {
        console.error('Error fetching data: ', response.statusText);
      }
    } catch (error) {
      console.error('Fetch exam register count error: ', error);
    }
  };

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Exam Registrations</Title>
        </div>
      </div>
      <ReactApexChart
        className="full-width"
        options={lineChart.options}
        series={lineChart.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;
