import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../images/logo.png";
import { FaTachometerAlt, FaUserPlus, FaBook, FaClipboardList, FaUserGraduate, FaAward } from "react-icons/fa";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>Admin Dashboard</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/" style={{ textDecoration: "none" }}>
            <span className="icon" style={{ background: page === "dashboard" ? color : "" }}>
              <FaTachometerAlt color={color} />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item className="menu-item-header" key="5">
          Management
        </Menu.Item>

        <Menu.Item key="2">
          <NavLink to="/create_class" style={{ textDecoration: "none" }}>
            <span className="icon" style={{ background: page === "create_class" ? color : "" }}>
              <FaUserPlus color={color} />
            </span>
            <span className="label">Create Class</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="3">
          <NavLink to="/quiz_table" style={{ textDecoration: "none" }}>
            <span className="icon" style={{ background: page === "quiz_table" ? color : "" }}>
              <FaBook color={color} />
            </span>
            <span className="label">Create Exam</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="4">
          <NavLink to="/exam_table" style={{ textDecoration: "none" }}>
            <span className="icon" style={{ background: page === "exam_table" ? color : "" }}>
              <FaClipboardList color={color} />
            </span>
            <span className="label">Manage Exam</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="5">
          <NavLink to="/create_scholarship" style={{ textDecoration: "none" }}>
            <span className="icon" style={{ background: page === "create_scholarship" ? color : "" }}>
              <FaAward color={color} />
            </span>
            <span className="label">Add Scholarships</span>
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item key="6">
          <NavLink to="/stud_result/:classId" style={{ textDecoration: "none" }}>
            <span className="icon" style={{ background: page === "stud_result" ? color : "" }}>
              <FaUserGraduate color={color} />
            </span>
            <span className="label">Student Results</span>
          </NavLink>
        </Menu.Item> */}

        <Menu.Item key="7">
          <NavLink to="/allotscholarships/:examId" style={{ textDecoration: "none" }}>
            <span className="icon" style={{ background: page === "allotscholarships" ? color : "" }}>
              <FaAward color={color} />
            </span>
            <span className="label">Allot Scholarships</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
