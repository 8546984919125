import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Button, Modal, Form, Input, message, Spin, Popconfirm } from "antd";
import { backendURL } from "../backendURL";

const AddClassForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      onCreate(values);
      form.resetFields();
    } catch (error) {
      console.error("Failed to add class:", error);
    }
  };

  return (
    <Modal
      open={visible}
      title="Add Class"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Class name"
          rules={[
            { required: true, message: "Please input the class name!" },
            { pattern: /^[0-9]+$/, message: "Please input a valid numeric value!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value >= 5 && value <= 12) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Class name must be between 5 and 12!"));
              },
            }),
          ]}
        >
          <Input type="number" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const AddClass = () => {
  const [visible, setVisible] = useState(false);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${backendURL}/class/getAll`);
      if (response.ok) {
        const data = await response.json();
        const classNames = data.data.map((item) => ({
          key: item._id,
          name: item.className,
        }));
        classNames.sort((a, b) => a.name.localeCompare(b.name));
        setClasses(classNames);
      } else {
        throw new Error("Failed to fetch classes");
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
      message.error("Failed to fetch classes");
    } finally {
      setLoading(false);
    }
  };

  const onCreate = async (values) => {
    try {
      const existingClass = classes.find((item) => item.name === values.name);
      if (existingClass) {
        message.error("Class name already exists");
        return;
      }

      const response = await fetch(`${backendURL}/class/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ className: values.name }),
      });

      if (response.ok) {
        fetchClasses();
        setVisible(false);
        message.success("Class added successfully");
      } else {
        const errorData = await response.json();
        console.error("Failed to add class:", errorData);
        message.error("Failed to add class");
      }
    } catch (error) {
      console.error("Error adding class:", error);
      message.error("Failed to add class");
    }
  };

  const onDelete = async (classId) => {
    try {
      const response = await fetch(`${backendURL}/class/${classId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const updatedClasses = classes.filter((item) => item.key !== classId);
        setClasses(updatedClasses);
        message.success("Class deleted successfully");
      } else {
        message.error("Failed to delete class");
      }
    } catch (error) {
      console.error("Error deleting class:", error);
      message.error("Failed to delete class");
    }
  };

  const columns = [
    {
      title: "Class Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Popconfirm
            title="Are you sure you want to delete this class?"
            onConfirm={() => onDelete(record.key)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" style={{ backgroundColor: "red", color: "white", marginBottom: "8px" }}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <>
      <Button
        type="primary"
        style={{ marginBottom: "16px" }}
        onClick={() => setVisible(true)}
      >
        Add Class
      </Button>
      <AddClassForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => setVisible(false)}
      />
      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Card bordered={false} title="Class Table">
            <div className="table-responsive" style={{ overflowX: 'auto' }}>
              {loading ? (
                <Spin size="large" />
              ) : (
                <Table
                  columns={columns}
                  dataSource={classes}
                  pagination={false}
                  rowKey="key"
                  style={{
                    background: "white",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                  tableStyle={{
                    background: "linear-gradient(to right, #1890ff, #40a9ff)",
                    borderRadius: "8px",
                  }}
                  components={{
                    header: {
                      cell: (props) => (
                        <th
                          {...props}
                          style={{
                            background: "linear-gradient(to right, #e6f7ff, #a0d6ff)",
                            color: "#111827",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        />
                      ),
                    },
                  }}
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddClass;
