import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AddClass from "./pages/AddClass";
import Main from "./layout/Main"
import "./styles/main.css";
import "./styles/responsive.css";
import CreateExamTable from "./pages/CreateExamTable";
import LoginPage from "./pages/auth/LoginPage";
import ExamTable from "./pages/ExamTable";
// import StudentResultsCorner from './pages/StudentsResultCorner'
import ShowQuestionsTable from "./pages/ShowQuestionsTable";
import Logout from "./pages/Logout";
import PrivateRoute from "./layout/PrivateRoute";
import AddScholarship from "./pages/AddScholarship";
import Profile from './pages/Profile'


import ScholarshipAllotmentCorner from "./pages/ScholarshipAllotmentCorner"
import ExamDetails from "./pages/ExamDetails";


function App() {
  return (      
    <Routes>
      <Route path="/admin/login"  element={<LoginPage/>} />
      <Route path="/logout"  element={<Logout/>} />
      <Route path="/"  element={<PrivateRoute><Main><Home /></Main></PrivateRoute>} />  
      <Route path="/profile"  element={<PrivateRoute><Main><Profile /></Main></PrivateRoute>} />  
      <Route path="/create_class"  element={<PrivateRoute><Main><AddClass /></Main></PrivateRoute>} />
      <Route path="/quiz_table"  element={<PrivateRoute><Main><CreateExamTable /></Main></PrivateRoute>} />
      <Route path="/exam_table"  element={<PrivateRoute><Main><ExamTable /></Main></PrivateRoute>} />
      <Route path="/examDetails/:examId"  element={<PrivateRoute><Main><ExamDetails /></Main></PrivateRoute>} />
      <Route path="/create_scholarship" element={<PrivateRoute><Main><AddScholarship /></Main></PrivateRoute>} />
      {/* <Route path="/stud_result/:classId"  element={<PrivateRoute><Main><StudentResultsCorner /></Main></PrivateRoute>} /> */}
      <Route path="/allotscholarships/:examId"  element={<PrivateRoute><Main><ScholarshipAllotmentCorner /></Main></PrivateRoute>} />
      <Route path="/questions/:examId" element={<PrivateRoute><Main><ShowQuestionsTable /></Main></PrivateRoute>} /> 
    </Routes>
  );
}

export default App;
