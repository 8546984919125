import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Form,
  Select,
  Input,
  message,
  Popconfirm,
  Upload,
} from "antd";
import { UploadOutlined } from '@ant-design/icons';

import { backendURL } from "../backendURL";

const { Option } = Select;

const UpdateScholarshipForm = ({
  visible,
  editingScholarship,
  onCancel,
  onUpdate,
  classId,
  form,
}) => {
  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      onUpdate(editingScholarship, values, form);
      form.resetFields();
    } catch (error) {
      console.error("Failed to update scholarship:", error);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Edit Scholarship"
      okText="Edit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleUpdate}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="classId"
          label="Class ID"
          hidden
          initialValue={classId}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="scholarshipName"
          label="Scholarship Name"
          initialValue={
            editingScholarship ? editingScholarship.scholarshipName : ""
          }
          rules={[
            {
              required: true,
              message: "Please enter the scholarship name!",
            },
          ]}
        >
          <Input type="text" placeholder="Enter Scholarship Name" />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Amount in Rs"
          initialValue={editingScholarship ? editingScholarship.amount : ""}
          rules={[
            {
              required: true,
              message: "Please enter the amount!",
            },
          ]}
        >
          <Input type="number" placeholder="Enter Amount" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const AddScholarship = () => {
  const [visible, setVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [scholarships, setScholarships] = useState([]);
  const [editingScholarship, setEditingScholarship] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const classesResponse = await fetch(`${backendURL}/class/getAll`);
        if (classesResponse.ok) {
          const classesData = await classesResponse.json();
          setClasses(classesData.data);
        } else {
          throw new Error("Failed to fetch classes");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to fetch data");
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    const fetchScholarships = async () => {
      try {
        const response = await fetch(`${backendURL}/scholarship/getAll`);
        if (response.ok) {
          const data = await response.json();
          console.log("Response scholarships",data.data)
          if (data.status) {
            setScholarships(data.data);
          } else {
            message.error("Failed to fetch scholarships");
          }
        } else {
          throw new Error("Failed to fetch scholarships");
        }
      } catch (error) {
        console.error("Failed to fetch scholarships:", error);
        message.error("Failed to fetch scholarships");
      }
    };


    fetchData();
    fetchScholarships();
  }, []);

  const showModal = () => {
    setVisible(true);
  };

  const showEditModal = (record) => {
    if (record) {
      setSelectedClass(record.classId);
      setEditingScholarship(record);
      setEditModalVisible(true);
      form.setFieldsValue({
        classId: record.classId,
        scholarshipName: record.scholarshipName,
        amount: record.amount,
      });
    } else {
      console.error("Invalid record:", record);
    }
  };

  const handleCancel = () => {
    setEditingScholarship(null);
    setVisible(false);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      console.log(values)
      const formData = new FormData();
      formData.append('classId', selectedClass);
      formData.append('scholarshipName', values.scholarshipName);
      formData.append('amount', values.amount);
      formData.append('photo', values.photo[0].originFileObj); // Ensure 'photo' matches backend expectation

      const response = await fetch(`${backendURL}/scholarship/create`, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        message.success('Scholarship created successfully!');
        setScholarships([...scholarships, data.data]);
        form.resetFields();
        setSelectedClass(null);
        setVisible(false);
      } else {
        message.error(data.message || 'Failed to create scholarship');
      }
    } catch (error) {
      console.error('Failed to create scholarship:', error);
      message.error('Failed to create scholarship');
    }
  };



  const handleDelete = async (record) => {
    try {
      const response = await fetch(
        `${backendURL}/scholarship/delete/${record._id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        message.success("Scholarship deleted successfully!");
        const updatedScholarships = scholarships.filter(
          (item) => item._id !== record._id
        );
        setScholarships(updatedScholarships);
      } else {
        message.error("Failed to delete scholarship");
      }
    } catch (error) {
      console.error("Error deleting scholarship:", error);
      message.error("Failed to delete scholarship");
    }
  };

  const handleEdit = async (record, values, form) => {
    try {
      console.log("Form values:", values);
      console.log("Record:", record);

      const payload = {
        classId: record.classId,
        scholarshipName: values.scholarshipName,
        amount: values.amount,
      };

      const response = await fetch(`${backendURL}/scholarship/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      console.log("Response:", data);

      if (response.ok) {
        message.success("Scholarship updated successfully!");
        const updatedScholarships = scholarships.map((item) => {
          if (item._id === record._id) {
            return {
              ...item,
              scholarshipName: data.data.scholarshipName,
              amount: data.data.amount,
            };
          }
          return item;
        });
        setScholarships(updatedScholarships);
        setEditModalVisible(false);
      } else {
        message.error(data.message || "Failed to update scholarship");
      }
    } catch (errorInfo) {
      console.error("Error updating scholarship:", errorInfo);
      message.error("Failed to update scholarship");
    }
  };

  const columns = [
    {
      title: "Class Name",
      dataIndex: "classId",
      key: "classId",
      render: (classId) => {
        console.log("Rendering classId:", classId);
        const selectedClass = classes.find((cls) => cls._id === classId);
        console.log("Selected class:", selectedClass);
        return selectedClass ? selectedClass.className : "";
      },
    },
    {
      title: "Scholarship Name",
      dataIndex: "ScholarshipName", 
      key: "ScholarshipName",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => showEditModal(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this scholarship?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <>
      <Button
        type="primary"
        className="tag-primary"
        style={{ marginBottom: "16px" }}
        onClick={showModal}
      >
        Add Scholarship
      </Button>
      <Modal
        visible={visible}
        title="Add Scholarship"
        okText="Add"
        cancelText="Cancel"
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="classId"
            label="Select Class"
            rules={[
              {
                required: true,
                message: "Please select the class!",
              },
            ]}
          >
            <Select
              placeholder="Select Class"
              onChange={(value) => setSelectedClass(value)}
              value={selectedClass}
            >
              {classes &&
                classes.map((cls) => (
                  <Option key={cls._id} value={cls._id}>
                    {cls.className}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="scholarshipName"
            label="Scholarship Name"
            rules={[
              {
                required: true,
                message: "Please enter the scholarship name!",
              },
            ]}
          >
            <Input type="text" placeholder="Enter Scholarship Name" />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Amount in Rs"
            rules={[
              {
                required: true,
                message: "Please enter the amount!",
              },
            ]}
          >
            <Input type="number" placeholder="Enter Amount" />
          </Form.Item>
          <Form.Item
            name="photo"
            label="Scholarship Photo"
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
          >
            <Upload name="photo" action={`${backendURL}/scholarship/create`} listType="picture">
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>

      <UpdateScholarshipForm
        visible={editModalVisible}
        editingScholarship={editingScholarship}
        onCancel={() => setEditModalVisible(false)}
        onUpdate={handleEdit}
        form={form}
      />
      <div className="tabled">
        <Card bordered={false} className="criclebox tablespace mb-24" title="Scholarships">
          <div className="table-responsive">
            <Table dataSource={scholarships} columns={columns} />
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddScholarship;
