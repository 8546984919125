// LogoutPage.js

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; 

function Logout() {
  const history = useNavigate();

  useEffect(() => {
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    history("/admin/login");
  }, [history]);

  return (
    <div>
      <h1>Logging out...</h1>
    </div>
  );
}

export default Logout;
