import React, { useState, useEffect } from 'react';
import { backendURL } from '../backendURL';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Profile = () => {
  const [adminDetails, setAdminDetails] = useState(null); // State to hold admin details
  const [showModal, setShowModal] = useState(false); // State for modal control
  const [modalContent, setModalContent] = useState(''); // State to manage modal content
  const [showOldPassword, setShowOldPassword] = useState(false); // State to toggle old password visibility
  const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle new password visibility

  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for snackbar visibility
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Severity of snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Message to display in snackbar

  const adminId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const response = await fetch(`${backendURL}/admin/${adminId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch admin details');
        }
        const data = await response.json();
        setAdminDetails(data);
      } catch (error) {
        console.error('Error fetching admin details:', error);
      }
    };

    fetchAdminDetails();
  }, [adminId]);

  // Function to handle showing the modal with change password fields
  const handleChangePasswordModal = () => {
    setModalContent('changePassword');
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Function to handle changing password
  const handleChangePassword = async (event) => {
    event.preventDefault();
    const { oldPassword, newPassword } = event.target;
      // Check if passwords match
      if (oldPassword === newPassword) {
        setSnackbarSeverity('error');
        setSnackbarMessage('New password cannot be the same as the old password');
        setSnackbarOpen(true);
        return;
      }

    try {
      const response = await fetch(`${backendURL}/admin/change-password/${adminId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          oldPassword: oldPassword.value,
          newPassword: newPassword.value,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to change password');
      }
      setSnackbarSeverity('success');
      setSnackbarMessage('Password changed successfully');
      setSnackbarOpen(true);
      handleCloseModal(); // Close modal after successful change
    } catch (error) {
      console.error('Error changing password:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to change password. Please try again.');
      setSnackbarOpen(true);
    }
  };

  // Function to toggle visibility of old password
  const handleToggleOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  // Function to toggle visibility of new password
  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  // Function to close the snackbar
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // Render loading state while fetching data
  if (!adminDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container py-4">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card">
            <div className="card-header">
              <h3 className="text-center">Admin Profile</h3>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <strong>Email:</strong> {adminDetails.username}
                </li>
                <li className="list-group-item">
                  <strong>Role:</strong> {adminDetails.role}
                </li>
              </ul>
            </div>
            {/* Add change password button */}
            <div className="card-footer text-center">
              <button className="btn btn-primary" onClick={handleChangePasswordModal}>
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Change Password */}
      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Change Password</h5>
              </div>
              <div className="modal-body">
                <form onSubmit={handleChangePassword}>
                  <div className="form-group mb-3">
                    <label htmlFor="oldPassword">Old Password</label>
                    <div className="input-group">
                      <input
                        type={showOldPassword ? 'text' : 'password'}
                        className="form-control"
                        id="oldPassword"
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={handleToggleOldPassword}
                      >
                        View
                      </button>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="newPassword">New Password</label>
                    <div className="input-group">
                      <input
                        type={showNewPassword ? 'text' : 'password'}
                        className="form-control"
                        id="newPassword"
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={handleToggleNewPassword}
                      >
                        View
                      </button>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary me-2">
                    Change Password
                  </button>
                  <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                    Close
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Modal backdrop */}
      {showModal && <div className="modal-backdrop fade show"></div>}

      {/* Snackbar for Alerts */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Profile;