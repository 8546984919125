import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { backendURL } from "../backendURL";
import { useEffect, useState } from "react";

function EChart() {
  const { Title } = Typography;
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: '#FFFFFF', 
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#FFFFFF', 
            fontSize: '12px'
          }
        }
      },
      colors: ['#FF4560'], 
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#000000'] 
        }
      }
    },
    series: [
      {
        name: "Total Students",
        data: []
      }
    ]
  });

  useEffect(() => {
    fetchStudentCount();
  }, []);

  const fetchStudentCount = async () => {
    try {
      const response = await fetch(`${backendURL}/student-count`);
      if (response.ok) {
        const data = await response.json();
        const categories = data.map(item => item.className);
        const seriesData = data.map(item => item.totalStudents);
        setChartData({
          options: {
            ...chartData.options,
            xaxis: {
              categories: categories,
              labels: {
                style: {
                  colors: '#FFFFFF',
                  fontSize: '12px'
                }
              }
            },
            yaxis: {
              labels: {
                style: {
                  colors: '#FFFFFF',
                  fontSize: '12px'
                }
              }
            },
            colors: ['#fff'], 
            dataLabels: {
              enabled: true,
              style: {
                colors: ['#000000'] 
              }
            }
          },
          series: [
            {
              name: "Total Students",
              data: seriesData
            }
          ]
        });
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error while fetching count", error);
    }
  };

  return (
    <>
      <div id="chart">
        <ReactApexChart
          className="bar-chart"
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={220}
        />
      </div>
      <div className="chart-visitor">
        <Title level={5}>Active Students</Title>
      </div>
    </>
  );
}

export default EChart;
