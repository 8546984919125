import { useState, useEffect } from "react";
import { backendURL } from "../backendURL";
import {
  Card,
  Col,
  Row,
  Typography,
} from "antd";
import {
  UserOutlined,
  SolutionOutlined,
  AuditOutlined,
  FundOutlined,
  DollarCircleOutlined,
  FileDoneOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
// import RecentExams from "./RecentExams";
import LineChart from "../chart/LineChart";
import EChart from "../chart/EChart";
import axios from "axios";
// import { TrophyFilled } from "@ant-design/icons";
// const { TextArea } = Input;

// CSS for gradient colors
const gradientStyles = {
  background: "linear-gradient(to right, #00c6ff, #0072ff)",
  color: "#fff",
  padding: "24px",
  borderRadius: "8px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  textAlign: 'center'
};

function Home() {
  const { Title } = Typography;
  const [exams, setExams] = useState([]);
  const [classCount, setClassCount] = useState(0);
  const [examCount, setExamCount] = useState(0);
  const [examRegisterCount, setExamRegisterCount] = useState(0);
  const [questionsCount, setQuestionsCount] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const [scholarshipCount, setScholarshipCount] = useState(0);
  const [studentsansCount, setStudentsansCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [totalExamCount, setTotalExamCount] = useState(0);
  const [paidExamCount, setPaidExamCount] = useState(0);
  const [unpaidExamCount, setUnpaidExamCount] = useState(0);
  const [chartData, setChartData] = useState([]);


  useEffect(() => {
    fetchData();
    fetchExam();
    getUsersCount();
    getClassesCount();
    getExamsCount();
    getRegExamCount();
    getResultCount();
    getScholarshipCount();
  }, []);

  const getUsersCount = async()=>{
    try{
      const response = await axios.get(`${backendURL}/student/getAllStudents`);
      setUserCount(response.data.count)
    }
    catch(err){
      console.log(err)
    }
  }

  const getClassesCount = async()=>{
    try{
      const response = await axios.get(`${backendURL}/class/getAll`);
      setClassCount(response.data.classCount)
    }
    catch(err){
      console.log(err)
    }
  }

  const getExamsCount = async()=>{
    try{
      const response = await axios.get(`${backendURL}/exam/getCount`);
      setPaidExamCount(response.data.paidCount)
      setUnpaidExamCount(response.data.unPaidCount)
    }
    catch(err){
      console.log(err)
    }
  }

  const getRegExamCount = async()=>{
    try {
      const response = await axios.get(`${backendURL}/getRegExamCount`);
      setExamRegisterCount(response.data.count)
    } catch (error) {
      console.log(error)
    }
  }

  const getResultCount=async()=>{
    try {
      const response  = await axios.get(`${backendURL}/result/getCount`)
      setResultCount(response.data.count)
    } catch (error) {
      console.log(error)
    }
  }

  const getScholarshipCount=async()=>{
    try {
      const response  = await axios.get(`${backendURL}/scholarship/getAll`)
      setScholarshipCount(response.data.count)
    } catch (error) {
      console.log(error)
    }
  }

  //for cards
  const fetchData = async () => {
    try {
      const response = await fetch(`${backendURL}/getCount`);

      if (response.ok) {
        const data = await response.json();


        // Handle counts as needed
        const {
          classCount,
          examRegisterCount,
          questionsCount,
          resultCount,
          scholarshipCount,
          studentsansCount,
          userCount,
          examCount,
          paidExamCount,
          unpaidExamCount,
        } = data.data;

        // Example: Set counts in state or use them directly
        setClassCount(classCount);
        setExamCount(examCount);
        setExamRegisterCount(examRegisterCount);
        setQuestionsCount(questionsCount);
        setResultCount(resultCount);
        setScholarshipCount(scholarshipCount);
        setStudentsansCount(studentsansCount);
        setUserCount(userCount);
        // Update state variables
        setTotalExamCount(examCount);
        setPaidExamCount(paidExamCount);
        setUnpaidExamCount(unpaidExamCount);

      } else {
        throw new Error("Failed to fetch counts");
      }
    } catch (error) {
      console.error("Error fetching counts:", error);
      // Handle error
    }
  };


  // const fetcStudenthData = async () => {
  //   try {
  //     const response = await fetch(`${backendURL}/student/getAll`);
  //     console.log("Response:", response);
  //     if (response.ok) {
  //       const students = await response.json();
  //       console.log("Students:", students); // Check what students array looks like

  //       // Example: transform data to count students per month
  //       const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  //       const currentDate = new Date(); // Get current date to compare with createdAt

  //       const studentCountByMonth = months.map((monthName, monthIndex) => {
  //         const count = students.filter(student => {
  //           const createdAt = new Date(student.createdAt);
  //           return createdAt.getMonth() === monthIndex && createdAt.getFullYear() === currentDate.getFullYear();
  //         }).length;
  //         return { month: monthName, numberOfStudents: count };
  //       });

  //       console.log("Student Count By Month:", studentCountByMonth); // Check the final data structure

  //       setChartData(studentCountByMonth);
  //     } else {
  //       console.error('Failed to fetch data');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };
  //exam table below graph
  const fetchExam = async () => {
    try {
      // // exam details 
      const data3 = await fetch(`${backendURL}/exam/getAll`);
      const allExam = await data3.json();
      if (allExam.status) {
        setExams(allExam.data);
      } else {
        console.error("Error fetching exams:", allExam.message);
      }
      //  console.log(exams)
    } catch (error) {
      console.error("Error fetching data:", error);

    }
  }

  return (
    <>
      <div className="layout-content">
        <div>
          {/* First Row of Cards */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card
                title="Total Users"
                bordered={false}
                style={{ ...gradientStyles, backgroundImage: "linear-gradient(to right, #00c6ff, #0072ff)" }}
                hoverable
              >
                <Title level={2} style={{ color: "black" }}>
                  <UserOutlined /> {userCount}
                </Title>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card
                title="Total Classes"
                bordered={false}
                style={{ ...gradientStyles, backgroundImage: "linear-gradient(to right, #00c6ff, #0072ff)" }}
                hoverable
              >
                <Title level={2} style={{ color: "black" }}>
                  <SolutionOutlined /> {classCount}
                </Title>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card
                title="Paid Exams"
                bordered={false}
                style={{ ...gradientStyles, backgroundImage: "linear-gradient(to right, #00c6ff, #0072ff)" }}
                hoverable
              >
                <Title level={2} style={{ color: "black" }}>
                  <DollarCircleOutlined /> {paidExamCount}
                </Title>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card
                title="Unpaid Exams"
                bordered={false}
                style={{ ...gradientStyles, backgroundImage: "linear-gradient(to right, #00c6ff, #0072ff)" }}
                hoverable
              >
                <Title level={2} style={{ color: "black" }}>
                  <AuditOutlined /> {unpaidExamCount}
                </Title>
              </Card>
            </Col>
          </Row>

          {/* Second Row of Cards */}
          <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card
                title="Total Exam Registrations"
                bordered={false}
                style={{ ...gradientStyles, backgroundImage: "linear-gradient(to left, #42fcdb, #3ee577)" }}
                hoverable
              >
                <Title level={2} style={{ color: "black" }}>
                  <FundOutlined /> {examRegisterCount}
                </Title>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card
                title="Total Results"
                bordered={false}
                style={{ ...gradientStyles, backgroundImage: "linear-gradient(to left, #42fcdb, #3ee577)" }}
              >
                <Title level={2} style={{ color: "black" }}>
                  <FileDoneOutlined /> {resultCount}
                </Title>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card
                title="Total Scholarships"
                bordered={false}
                style={{ ...gradientStyles, backgroundImage: "linear-gradient(to left, #42fcdb, #3ee577)" }}
                hoverable
              >
                <Title level={2} style={{ color: "black" }}>
                  <TrophyOutlined /> {scholarshipCount}
                </Title>
              </Card>
            </Col>
          </Row>
        </div>

        {/* Charts */}
        <Row gutter={[24, 0]} style={{ marginTop: '16px' }}>
          <Col xs={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <EChart data={userCount} />
            </Card>
          </Col>
          <Col xs={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <LineChart />
            </Card>
          </Col>


          {/* <Col xs={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
            <RecentExams exams={exams} />

            </Card>
          </Col> */}

        </Row>

        {/* Messages */}
        {/* <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">

        <Card bordered={false} className="criclebox cardbody h-full">
          <div className="project-ant">
            <div>
              <Title level={5}>Messages</Title>
            </div>
          </div>
          <div className="ant-list-box table-responsive">
            <table className="width-100">
              <thead>
                <tr>
                  <th>Messages</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {list.map((d, index) => (
                  <tr key={index}>
                    <td>
                      <div className="message-space">{d.message}</div>
                    </td>
                    <td>
                      <Button type="primary" onClick={handleReplyClick}>
                        Reply
                      </Button>
                      {replyVisible && ( // Conditionally render reply box based on state
                        <div>
                          <TextArea
                            rows={4}
                            value={replyText}
                            onChange={(e) => setReplyText(e.target.value)}
                          />
                          <Button onClick={handleReplySubmit}>Submit</Button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </Col>
    </Row> */}
      </div>
    </>
  );
}

export default Home;
