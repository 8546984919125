import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Form,
  Select,
  Input,
  message,
  Spin,
  Radio , Popconfirm
} from "antd";
import { backendURL } from "../backendURL";

const { Option } = Select;

const CreateQuizForm = ({ visible, onCreate, onCancel, classes }) => {
  const [form] = Form.useForm();
  const [isPaid, setIsPaid] = useState(false);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      onCreate(values);
      form.resetFields();
      setIsPaid(false);
    } catch (error) {
      console.error("Failed to create exam:", error);
    }
  };

  // Sort classes by class name in ascending order
  const sortedClasses = classes
    .slice()
    .sort((a, b) => a.className.localeCompare(b.className));

    // handle exam paid or not
    const handlePaidChange = (e) => {
      setIsPaid(e.target.value === 'yes');
    };

  return (
    <Modal
      visible={visible}
      title="Create Exam"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="classId"
          label="Select Class"
          rules={[
            {
              required: true,
              message: "Please select the class!",
            },
          ]}
        >
          <Select placeholder="Select Class">
            {sortedClasses.map((cls) => (
              <Option key={cls._id} value={cls._id}>
                {cls.className}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="examName"
          label="Exam Name"
          rules={[
            {
              required: true,
              message: "Please enter the Exam Name!",
            },
          ]}
        >
          <Input type="text" placeholder="Enter Exam Name" />
        </Form.Item>
        <Form.Item
          name="duration"
          label="Exam Duration in minutes"
          rules={[
            {
              required: true,
              message: "Please enter the Exam Duration!",
            },
          ]}
        >
          <Input type="text" placeholder="Enter Exam Duration" />
        </Form.Item>
        <Form.Item
          name="maxMarks"
          label="Max Marks"
          rules={[
            {
              required: true,
              message: "Please enter the max marks!",
            },
          ]}
        >
          <Input type="number" placeholder="Enter Max Marks" />
        </Form.Item>
        <Form.Item
          name="noOfQue"
          label="Number of Questions"
          rules={[
            {
              required: true,
              message: "Please enter the number of questions!",
            },
          ]}
        >
          <Input type="number" placeholder="Enter Number of Questions" />
        </Form.Item>

        <Form.Item
          name="isPaid"
          label="Is Exam Paid"
          rules={[
            {
              required: true,
              message: "Please select if the exam is paid!",
            },
          ]}
        >
          <Radio.Group onChange={handlePaidChange}>
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Radio.Group>
        </Form.Item>
        {isPaid && (
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Please enter the amount!",
              },
            ]}
          >
            <Input type="number" placeholder="Enter Amount" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

const AddQuestionForm = ({ visible, onCreate, onCancel, classId }) => {
  const [correctAnswer, setCorrectAnswer] = useState(null); // State to store the correct answer

  const validateCorrectAnswer = (rule, value) => {
    // Get values of options A, B, C, D from the form
    const { optionA, optionB, optionC, optionD } = form.getFieldsValue(['optionA', 'optionB', 'optionC', 'optionD']);
    
    // Check if the correct answer matches any of the options
    if (value !== optionA && value !== optionB && value !== optionC && value !== optionD) {
      return Promise.reject('Correct answer does not match any of the options (A, B, C, D)');
    }
    return Promise.resolve();
  };

  const handleChangeCorrectAnswer = (value) => {
    setCorrectAnswer(value); // Update the state with the selected correct answer
  };
  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      onCreate(values);
      form.resetFields();
    } catch (error) {
      console.error("Failed to add question:", error);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Add Question"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form form={form} layout="vertical">
      <Form.Item name="classId" label="Class ID" hidden initialValue={classId}>
        <Input />
      </Form.Item>
      <Form.Item
        name="setType"
        label="Select Set"
        rules={[
          {
            required: true,
            message: "Please select the set!",
          },
        ]}
      >
        <Select placeholder="Select the Set">
          <Option value="A">Set A</Option>
          <Option value="B">Set B</Option>
          <Option value="C">Set C</Option>
          <Option value="D">Set D</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="questionText"
        label="Question"
        rules={[
          {
            required: true,
            message: "Please enter the question!",
          },
        ]}
      >
        <Input placeholder="Enter Question" />
      </Form.Item>
      <Form.Item
        name="optionA"
        label="Option A"
        rules={[
          {
            required: true,
            message: "Please enter option A!",
          },
        ]}
      >
        <Input placeholder="Enter Option A" />
      </Form.Item>
      <Form.Item
        name="optionB"
        label="Option B"
        rules={[
          {
            required: true,
            message: "Please enter option B!",
          },
        ]}
      >
        <Input placeholder="Enter Option B" />
      </Form.Item>
      <Form.Item
        name="optionC"
        label="Option C"
        rules={[
          {
            required: true,
            message: "Please enter option C!",
          },
        ]}
      >
        <Input placeholder="Enter Option C" />
      </Form.Item>
      <Form.Item
        name="optionD"
        label="Option D"
        rules={[
          {
            required: true,
            message: "Please enter option D!",
          },
        ]}
      >
        <Input placeholder="Enter Option D" />
      </Form.Item>
      <Form.Item
        name="correctAnswer"
        label="Correct Answer"
        rules={[
          {
            required: true,
            message: "Please enter the correct answer!",
          },
          {
            validator: validateCorrectAnswer, // Custom validation function
          },
        ]}
      >
         <Input placeholder="Enter Correct Answer" />
      </Form.Item>
    </Form>
    </Modal>
  );
};

const CreateExamTable = () => {
  const [visible, setVisible] = useState(false);
  const [questionModalVisible, setQuestionModalVisible] = useState(false);
  const [classes, setClasses] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [selectedExam, setSelectedExamId] = useState(null);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchClasses = async () => {
    try {
      const response = await fetch(`${backendURL}/class/getAll`);
      if (response.ok) {
        const data = await response.json();
        console.log("class data",data.data)
       
        setClasses(data.data);
      } else {
        throw new Error("Failed to fetch classes");
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
      message.error("Failed to fetch classes");
    }
  };

  const fetchQuizzes = async () => {
    try {
      const response = await fetch(`${backendURL}/exam/getAll`);
      if (response.ok) {
        const data = await response.json();
        if (data.status) {
          setQuizzes(data.data);
          setLoading(false);
        } else {
          message.error("Failed to fetch quizzes");
        }
      } else {
        throw new Error("Failed to fetch quizzes");
      }
    } catch (error) {
      console.error("Failed to fetch quizzes:", error);
      message.error("Failed to fetch quizzes");
    }
  };

  useEffect(() => {
    fetchClasses();
    fetchQuizzes();
  }, []);

  const onCreateQuiz = async (values) => {
    try {
      const response = await fetch(`${backendURL}/exam/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          description: `Set ${values.examName} for Class ${values.classId}`,
          classId: values.classId,
          duration:values.duration,
          examName: values.examName,
          maxMarks: values.maxMarks,
          isActive: values.isActive,
          isPaid: values.isPaid,
          noOfQue: values.noOfQue,
          amount: values.isPaid === 'yes' ? values.amount : 0,
        }),
      });

      if (response.ok) {
        message.success("Quiz created successfully");
        setVisible(false);
        fetchQuizzes();
      } else {
        const errorData = await response.json();
        console.error("Failed to create quiz:", errorData);
        message.error("Failed to create quiz");
      }
    } catch (error) {
      console.error("Error creating quiz:", error);
      message.error("Error creating quiz");
    }
  };

  const onCreateQuestion = async (values) => {
    try {
      const {
        setType,
        questionText,
        optionA,
        optionB,
        optionC,
        optionD,
        correctAnswer,
      } = values;

      const optionsArray = [optionA, optionB, optionC, optionD].filter(Boolean);

      const response = await fetch(`${backendURL}/question/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          classId: values.classId,
          setType,
          questionText,
          options: optionsArray,
          correctAnswer,
          examId: selectedExam,
        }),
      });

      const responseData = await response.json();

      if (response.status === 201) {
        message.success("Question added successfully");
        setQuestionModalVisible(false);
      } else {
        console.error("Failed to add question:", responseData);
        message.error("Failed to add question");
      }
    } catch (error) {
      console.error("Failed to add question:", error);
      message.error("Failed to add question");
    }
  };

  const showQuestionModal = (examId, classId) => {
    setSelectedExamId(examId);
    setSelectedClassId(classId);
    setQuestionModalVisible(true);
  };

  const columns = [
    {
      title: "Class",
      dataIndex: "classId",
      key: "classId",
      render: (classId) => {
        const selectedClass = classes.find((cls) => cls._id === classId);
        return selectedClass ? selectedClass.className : "Unknown Class";
      },
      width: "20%",
    },
    {
      title: "Exam Name",
      dataIndex: "examName",
      key: "examName",
      width: "20%",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div>
          <Button
            onClick={() => showQuestionModal(record._id, record.classId)}
            style={{ marginRight: 8 }}
          >
            Add Question
          </Button>
         
          <Popconfirm
            title="Are you sure you want to delete this quiz?"
            onConfirm={() => onDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" style={{ backgroundColor: "red", color: "white" }}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
      width: "20%",
    },
  ];

  

  const onDelete = async (examId) => {
    try {
      const response = await fetch(`${backendURL}/exam/${examId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        message.success("Exam deleted successfully");
        fetchQuizzes(); // Refresh quizzes list after deletion
      } else {
        const errorData = await response.json();
        console.error("Failed to delete exam:", errorData);
        message.error("Failed to delete exam");
      }
    } catch (error) {
      console.error("Failed to delete exam:", error);
      message.error("Failed to delete exam");
    }
  };

  return (
    <>
      <Button
        type="primary"
        className="tag-primary"
        style={{ marginBottom: "16px" }}
        onClick={() => setVisible(true)}
      >
        Create Exam
      </Button>
      <Spin spinning={loading}>
        <CreateQuizForm
          visible={visible}
          onCreate={onCreateQuiz}
          onCancel={() => setVisible(false)}
          classes={classes}
        />
        <AddQuestionForm
          visible={questionModalVisible}
          onCreate={onCreateQuestion}
          onCancel={() => setQuestionModalVisible(false)}
          classId={selectedClassId}
        />
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Exam Table"
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={quizzes}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default CreateExamTable;
