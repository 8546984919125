import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Button, Modal, Input, message } from "antd";
import { useNavigate } from 'react-router-dom';
import { backendURL } from "../backendURL";

const ExamTable = () => {
  const [classes, setClasses] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [quizIds, setQuizIds] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [updateModalVisible, setUpdateModalVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchClasses();
    fetchQuizzes();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await fetch(`${backendURL}/class/getAll`);
      if (response.ok) {
        const data = await response.json();
        setClasses(data.data);
      } else {
        throw new Error('Failed to fetch classes');
      }
    } catch (error) {
      console.error('Error fetching classes:', error);
      message.error('Failed to fetch classes');
    }
  };

  const fetchQuizzes = async () => {
    try {
      const response = await fetch(`${backendURL}/exam/getAll`);
      if (response.ok) {
        const data = await response.json();
        if (data.status) {
          const fetchedQuizIds = data.data.map(quiz => quiz._id);
          setQuizzes(data.data);
          setQuizIds(fetchedQuizIds);
        } else {
          message.error('Failed to fetch exams');
        }
      } else {
        throw new Error('Failed to fetch exams');
      }
    } catch (error) {
      console.error('Failed to fetch exams:', error);
      message.error('Failed to fetch exams');
    }
  };

  const handleShowQuestions = (examId) => {
    navigate(`/questions/${examId}`);
  };

  const handleUpdateQuestion = (examId, questionId) => {
    const question = quizzes.find(q => q._id === questionId);
    setCurrentQuestion({ ...question, examId });
    setUpdateModalVisible(true);
  };

  const handleModalOk = async () => {
    const { classId, questionText, options, correctAnswer } = currentQuestion;

    try {
      const response = await fetch(`${backendURL}/question/${currentQuestion._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          classId,
          questionText,
          options,
          correctAnswer,
          examId: currentQuestion.examId,
        }),
      });

      const data = await response.json();
      if (data.status) {
        message.success(data.message);
        fetchQuizzes();
        setUpdateModalVisible(false);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.error("Error updating question:", error);
      message.error("Failed to update question");
    }
  };

  const handleModalCancel = () => {
    setUpdateModalVisible(false);
  };

  const columns = [
    {
      title: "Class",
      dataIndex: "classId",
      key: "classId",
      render: (classId) => {
        const selectedClass = classes.find(cls => cls._id === classId);
        return selectedClass ? selectedClass.className : classId;
      },
      width: "20%",
    },
    {
      title: "Exam Name",
      dataIndex: "examName",
      key: "examName",
      width: "20%",
    },
    {
      title: "Questions",
      key: "questions",
      render: (_, record, index) => (
        <Button type="primary" onClick={() => handleShowQuestions(quizIds[index])}>
          Show Questions 
        </Button>
      ),
      width: "20%",
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Exam Table"
          >
            <Table
              columns={columns}
              dataSource={quizzes}
              pagination={false}
              className="ant-border-space"
            />
          </Card>
        </Col>
      </Row>

      <Modal
        title="Update Question"
        visible={updateModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Input
          placeholder="Question"
          value={currentQuestion.questionText}
          onChange={(e) => setCurrentQuestion({ ...currentQuestion, questionText: e.target.value })}
          style={{ marginBottom: 10 }}
        />
        {currentQuestion.options?.map((option, index) => (
          <Input
            key={index}
            placeholder={`Option ${String.fromCharCode(65 + index)}`} 
            value={option}
            onChange={(e) => {
              const options = [...(currentQuestion.options || [])];
              options[index] = e.target.value;
              setCurrentQuestion({ ...currentQuestion, options });
            }}
            style={{ marginBottom: 10 }}
          />
        ))}
        <Input
          placeholder="Correct Answer"
          value={currentQuestion.correctAnswer}
          onChange={(e) => setCurrentQuestion({ ...currentQuestion, correctAnswer: e.target.value })}
        />
      </Modal>
    </div>
  );
};

export default ExamTable;
