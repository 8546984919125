import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for the spinner
import Alert from '@mui/material/Alert'; // Import Alert for displaying messages
import { backendURL } from '../../backendURL';

const LoginPage = () => {
  const navigate = useNavigate();
  const [loginFormData, setLoginFormData] = useState({
    username: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData({ ...loginFormData, [name]: value });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${backendURL}/admin/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginFormData)
      });
      
      if (!response.ok) {
        throw new Error('Failed to login');
      }
      
      const data = await response.json();
      console.log('Login successful:', data);
      
      // Save token and user ID to localStorage
      localStorage.setItem('token', data.token);
      localStorage.setItem('userId', data.userId);
      
      // Show success message
      setSuccess('Login successful!');
      
      // Redirect to admin page after successful login
      navigate('/');
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Failed to login. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom>
        Log In
      </Typography>
      <form style={{ width: '100%', maxWidth: 400 }} onSubmit={handleLoginSubmit} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          value={loginFormData.username}
          onChange={handleInputChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={loginFormData.password}
          onChange={handleInputChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: 16 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Log In'}
        </Button>
        {error && <Alert severity="error" style={{ marginTop: 8 }}>{error}</Alert>}
        {success && <Alert severity="success" style={{ marginTop: 8 }}>{success}</Alert>}
      </form>
    </Container>
  );
};

export default LoginPage;
